import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewentryAdim1Component } from './newentryadim1/newentryadim1.component';
import { EditentryAdim1Component } from './editentryadim1/editentryadim1.component';
import { EditentryAdim2Component } from './editentryadim2/editentryadim2.component';
import { editentryadim4Component } from './editentryadim4/editentryadim4.component';
import { editentryadim8Component } from './editentryadim8/editentryadim8.component';
import { editentryadim9Component } from './editentryadim9/editentryadim9.component';
import { editentryadim10Component } from './editentryadim10/editentryadim10.component';
import { editentryadim11Component } from './editentryadim11/editentryadim11.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TamamlanmamisComponent } from './tamamlanmamis/tamamlanmamis.component';
import { HomepageComponent } from './homepage/homepage.component';
import { UcretlendirmeComponent } from './ucretlendirme/ucretlendirme.component';
import { HesapbilgileriComponent } from './hesapbilgileri/hesapbilgileri.component';
import { BankaHesapBilgileriComponent } from './bankahesapbilgileri/bankahesapbilgileri.component';
import { CalismaAlaniComponent } from './calismaalani/calismaalani.component';
import { EditentryAdim3Component } from './editentryadim3/editentryadim3.component';

const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{ path: 'home', component: HomepageComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'tamamlanmamis', component: TamamlanmamisComponent },
	{ path: 'ucretlendirme', component: UcretlendirmeComponent },
	{ path: 'bankahesapbilgileri', component: BankaHesapBilgileriComponent },
	{ path: 'hesapbilgileri', component: HesapbilgileriComponent },
	{ path: 'calismaalani', component: CalismaAlaniComponent },
	{ path: 'newentry', component: NewentryAdim1Component },
	{ path: 'editentry', redirectTo: '/dashboard', pathMatch: 'full' },
	{ path: 'editentry/', redirectTo: '/dashboard', pathMatch: 'full' },
	{ path: 'editentry/:EntryId/1', component: EditentryAdim1Component },
	{ path: 'editentry/:EntryId/2', component: EditentryAdim2Component },
	{ path: 'editentry/:EntryId/3', component: EditentryAdim3Component },
	{ path: 'editentry/:EntryId/4', component: editentryadim4Component, data: { Adim: 4} },
	{ path: 'editentry/:EntryId/5', component: editentryadim4Component, data: { Adim: 5 } },
	{ path: 'editentry/:EntryId/6', component: editentryadim4Component, data: { Adim: 6 } },
	{ path: 'editentry/:EntryId/7', component: editentryadim4Component, data: { Adim: 7 } },
	{ path: 'editentry/:EntryId/8', component: editentryadim8Component },
	{ path: 'editentry/:EntryId/9', component: editentryadim9Component },
	{ path: 'editentry/:EntryId/10', component: editentryadim10Component },
	{ path: 'editentry/:EntryId/11', component: editentryadim11Component },
  	{ path: '**', component: HomepageComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
