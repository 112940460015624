import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { MessageServiceService } from '../services/message-service.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
	selector: 'app-calismaalani',
	templateUrl: './calismaalani.component.html',
	styleUrls: ['./calismaalani.component.css']
})
export class CalismaAlaniComponent implements OnInit {
	Icerik = ""
	subscription: Subscription;
	constructor(
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
	) {
		this.Icerik = this.globals.Yonetim.CalismaAlani
	}

	ngOnInit() {
		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.zone.run(() => {
					this.Icerik = this.globals.Yonetim.CalismaAlani
				});
				
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
