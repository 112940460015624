import { Component, OnInit, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { MessageServiceService } from '../services/message-service.service';
import { Subscription } from 'rxjs/Subscription';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";

@Component({
	selector: 'app-hesapbilgileri',
	templateUrl: './hesapbilgileri.component.html',
	styleUrls: ['./hesapbilgileri.component.css']
})
export class HesapbilgileriComponent implements OnInit {
	PageData: any;
	Yuklendi = false;
	isLoading = false;
	subscription: Subscription;
	GlobalYonetim: any;

	AdSoyadClass = '';
	FirmaAdiClass = '';
	GoreviClass = '';
	TelefonClass = '';

	constructor(
		private toastr: ToastrService,
		private http: HttpClient,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private cookieService: CookieService,
	) {

	}

	ngOnInit() {
		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.Yonetim
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Kullanici') {
				this.GlobalYonetim = this.globals.Yonetim
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/HesapBilgileri', { params, headers }).subscribe(
			(response) => {
				this.PageData = response;
				this.Yuklendi = true;
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	Kaydet() {
		this.AdSoyadClass = '';
		this.FirmaAdiClass = '';
		this.GoreviClass = '';
		this.TelefonClass = '';


		if (this.PageData['AdSoyad'].trim().length < 5) {
			this.AdSoyadClass = 'invalid';
		}
		else {
			this.AdSoyadClass = 'valid';
		}
		if (this.PageData['FirmaAdi'].trim().length < 5) {
			this.FirmaAdiClass = 'invalid';
		}
		else {
			this.FirmaAdiClass = 'valid';
		}
		if (this.PageData['Gorev'].trim().length < 5) {
			this.GoreviClass = 'invalid';
		}
		else {
			this.GoreviClass = 'valid';
		}
		if (this.PageData['Telefon'].trim().length < 5) {
			this.TelefonClass = 'invalid';
		}
		else {
			this.TelefonClass = 'valid';
		}


		if (
			this.AdSoyadClass === 'invalid' ||
			this.FirmaAdiClass === 'invalid' ||
			this.GoreviClass === 'invalid' ||
			this.TelefonClass === 'invalid'
		) {
			return;
		}

		this.isLoading = true;
		this.PageData['GuncellemeTarihi'] = new Date();

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/HesapBilgileri', this.PageData, { params, headers }).subscribe(
			(response) => {
				console.log(response['Token']);
				console.log(response);
				this.cookieService.set('Token', response['Token'], 365, '/', 'effieturkiye.org');
				this.globals.User.Token = response['Token'];
				this.globals.User.Decoded = jwt_decode(this.globals.User.Token);

				this.isLoading = false;
				this.showSuccess('Hesap bilgileriniz kaydedildi.');
			},
			error => {
				//  console.log('Error', error)
				this.isLoading = false;
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

	}

}
