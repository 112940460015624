import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim1',
	templateUrl: './editentryadim1.component.html',
	styleUrls: ['./editentryadim1.component.css']
})
export class EditentryAdim1Component implements OnInit {
	Kategoriler: any;
	subscription: Subscription;
	EntryId;
	PageData: any;
	OrgData: any;
	SelectedSubKatData: any;
	SelectedKatIndex = -1;
	SelectedSubKatIndex = -1;
	Yuklendi = false;
	IlkSurdurulebilirlik = 0;
	SonSurdurulebilirlik = 0;
	Yil = '';
	GlobalYonetim: any;


	constructor(
		private globals: Globals,
		private http: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private zone: NgZone,
		private messageService: MessageServiceService,
	) {

	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			// console.log(this.Kategoriler);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				// console.log("bbb " + this.Yil);
				// console.log(this.Kategoriler);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {
		let result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		// console.log(result);

		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);

			this.OrgData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.Adim11Durum === 1) {
				this.zone.run(() => {
					this.router.navigateByUrl('/dashboard');
				});
			}

			this.IlkSurdurulebilirlik = this.PageData['Surdurulebilir'];
			this.SonSurdurulebilirlik = this.PageData['Surdurulebilir'];

			let that = this;
			this.zone.run(() => {
				let KatSay = 0;
				this.Kategoriler.forEach(element => {
					let SubKatSay = 0;
					if (element['Id'] == that.PageData.KategoriId) {
						that.SelectedKatIndex = KatSay;
						element['SubKategoriler'].forEach(SubElement => {
							if (SubElement['Id'] == that.PageData.SubKategoriId) {
								that.SelectedSubKatIndex = SubKatSay;
								that.SelectedSubKatData = element['SubKategoriler'];
							}
							SubKatSay++;
						});
					}
					KatSay++;
				});
				// console.log(that.SelectedSubKatIndex);
				// console.log(that.SelectedSubKatData);
				that.Yuklendi = true;
			});
		}
	}

	KategoriChanged(Index) {
		this.SelectedKatIndex = Index;
		this.PageData['KategoriId'] = this.Kategoriler[Index]['Id'];
		this.SelectedSubKatData = this.Kategoriler[Index]['SubKategoriler'];

		this.SelectedSubKatIndex = -1;
		this.PageData['SubKategoriId'] = 0;
		this.SonSurdurulebilirlik = 0;
		this.PageData.Surdurulebilir = 0;
		if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}
	}

	SubKategoriChanged(Index) {

		this.SelectedSubKatIndex = Index;
		this.PageData['SubKategoriId'] = this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][Index]['Id'];
		this.SonSurdurulebilirlik = this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][Index]['Surdurulebilir'];
		this.PageData['Surdurulebilir'] = this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][Index]['Surdurulebilir'];
		
		// console.log(this.IlkSurdurulebilirlik + " // " + this.SonSurdurulebilirlik)
		if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
			this.showWarning('Adım 3,4,5,6 değişecek');
		}
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			if (this.SelectedKatIndex === -1) {
				this.showWarning('Kategori Seçiniz.');
				return;
			}
			if (this.SelectedSubKatIndex === -1) {
				this.showWarning('Alt Kategori Seçiniz.');
				return;
			}

			if (this.PageData['Adim2Durum'] === 0 && sayfa > 2) {
				this.showWarning('Önce ikinci adımı tamamlamalısınız.');
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/2');
				return;
			}

			if (JSON.stringify(this.PageData) !== JSON.stringify(this.OrgData)) {
				let UpdateData;

				if (this.IlkSurdurulebilirlik !== this.SonSurdurulebilirlik) {
					UpdateData = {
						'KategoriId': this.PageData['KategoriId'],
						'SubKategoriId': this.PageData['SubKategoriId'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'SurdurulebilirChanged': 1,
						'Id': this.EntryId
					}
				}
				else {
					UpdateData = {
						'KategoriId': this.PageData['KategoriId'],
						'SubKategoriId': this.PageData['SubKategoriId'],
						'Surdurulebilir': this.PageData['Surdurulebilir'],
						'SurdurulebilirChanged': 0,
						'Id': this.EntryId
					}
				}

				// console.log(JSON.stringify(UpdateData));

				const params = new HttpParams();
				let headers = new HttpHeaders();
				headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
				headers = headers.append('Content-Type', 'application/json');
				headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

				this.http.post('https://api.effieturkiye.org/ProjeKategoriler', UpdateData, { params, headers }).subscribe(
					(response) => {
						const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
						this.globals.projeler[index] = response;
						this.showSuccess("İlk adım kaydedildi, yönlendiriliyorsunuz.");
						this.messageService.sendMessage('ProjeGuncellendi');
						this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
					},
					error => {
						//  console.log('Error', error)
						this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				);
			}
			else {
				this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
			}
		}
		else {
			this.showError('Bir hata oluştu.');
		}
	}

}
