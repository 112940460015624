<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <header class="dev-entrant-layout">
                <h1>Tamamlanmış Başvurular</h1>
                <div style="margin-top: 10px;">
                    <ul>
                        <li>Bu sayfada <strong>tamamlanmış</strong> başvurularınız listelenmektedir.</li>
                        <li>Başvurularınızı tamamladıktan sonra, başvurunuzun son halini PDF olarak indirebilir ve yüklemiş olduğunuz dosyaları görebilirsiniz.</li>
                        <li>Eğer başvurunuzu <strong>tamamen silmek istiyorsanız</strong> “Sil” butonunu kullanabilirsiniz. Başvurunuzu sildiğiniz noktada girmiş olduğunuz bütün veriler sistemden silinecektir.
                            <strong>Silme işlemini geri almak mümkün değildir.</strong></li>
                    </ul>
                </div>
            </header>
            <table class="tbl-entrant-entry-listing" *ngFor="let item of PageData; let i = index;">
                <tbody>
                    <tr style="height: 20px;">
                        <td><a [routerLink]="['/editentry/',item.Id,1]"><strong>#{{item.Id}}</strong></a></td>
                        <td><a [routerLink]="['/editentry/',item.Id,1]"><strong>{{item.MarkaAdi}}</strong></a>
                            <br>{{item.KampanyaAdi}}
                            <br>{{item.Kategori}} - {{item.SubKategori}}
                        </td>
                        <td style="width: 130px;">
                            <a class="PDFButton" href="https://pdfebook.effieturkiye.org/pdfler/{{item.Id}}/1.pdf" target="_blank">Sayfa Doğrulama PDF'i İndir</a>
                        </td>
                        <td style="width: 100px;">
                            <a class="PDFButton" href="https://pdfebook.effieturkiye.org/pdfler/{{item.Id}}/2.pdf" target="_blank">Juri PDF'i İndir</a>
                        </td>
                        <td style="width: 120px;">
                            <button class="PDFButton" (click)="DosyalariGoster(DosyalarModal, item.Id)">Dosyaları Göster</button>
                        </td>
                        <td style="width: 100px;">
                            <button class="SilButton" [swal]="DosyaSilSwal" (confirm)="ProjeSil(item.Id)">Sil</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </article>
    </div>
</section>

<ng-template #DosyalarModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Proje Dosyaları</h4>
    </div>
    <div class="modal-body">
        <div class="px-3">
            <table class="table">
                <thead>
                    <tr>
                        <th>Dosya Adı</th>
                        <!-- <th>Orj. Dosya Adı</th> -->
                        <th>Tür</th>
                        <th>Boyut</th>
                        <th>Tarih</th>
                        <th>Aç</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of Dosyalar">
                        <td style="word-break: break-all;">{{item.DosyaAdi}}</td>
                        <!-- <td style="word-break: break-all;">{{item.OrjDosyaAdi}}</td> -->
                        <td style="word-break: break-all;">{{DosyaType(item.Type)}}</td>
                        <td style="word-break: break-all;">{{formatBytes(item.Boyut)}}</td>
                        <td style="word-break: break-all;">{{item.Tarih | date:'dd.MM.yyyy HH:mm'}}</td>
                        <td>
                            <a *ngIf="item.Type !== 'Link'" target="_blank" href="https://api.effieturkiye.org/storage/dosyalar/{{SelectedBasvuruId}}/{{item.DosyaAdi}}" class="btn btn-sm btn-raised ModalEkle white">Aç</a>
                            <a *ngIf="item.Type === 'Link'" target="_blank" href="{{HTTPControl(item.DosyaAdi)}}" class="btn btn-sm btn-raised ModalEkle white">Aç</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-block btn-raised white float-left ModalKapat" (click)="c('')">Kapat</button>
    </div>
</ng-template>