import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Globals } from './providers/globals/globals';
import { MessageServiceService } from './services/message-service.service';
import { ToastrModule } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import { HttpClientModule } from '@angular/common/http';
import { QuillModule } from 'ngx-quill'
import { FileUploadModule } from 'ng2-file-upload';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NumberOnlyDirective } from './services/number.directive';
import { environment } from '../environments/environment';
import { HtmlPipe } from './html-pipe.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';

import { NavbarComponent } from './navbar/navbar.component';
import { SolmenuComponent } from './solmenu/solmenu.component';
import { SagmenuComponent } from './sagmenu/sagmenu.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TamamlanmamisComponent } from './tamamlanmamis/tamamlanmamis.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NewentryAdim1Component } from './newentryadim1/newentryadim1.component';
import { EditentryAdim1Component } from './editentryadim1/editentryadim1.component';
import { EditentryAdim2Component } from './editentryadim2/editentryadim2.component';
import { EditentryAdim3Component } from './editentryadim3/editentryadim3.component';
import { editentryadim4Component } from './editentryadim4/editentryadim4.component';
import { editentryadim8Component } from './editentryadim8/editentryadim8.component';
import { editentryadim9Component } from './editentryadim9/editentryadim9.component';
import { editentryadim10Component } from './editentryadim10/editentryadim10.component';
import { editentryadim11Component } from './editentryadim11/editentryadim11.component';
import { FooterComponent } from './footer/footer.component';
import { UcretlendirmeComponent } from './ucretlendirme/ucretlendirme.component';
import { BankaHesapBilgileriComponent } from './bankahesapbilgileri/bankahesapbilgileri.component';
import { HesapbilgileriComponent } from './hesapbilgileri/hesapbilgileri.component';
import { CalismaAlaniComponent } from './calismaalani/calismaalani.component';
// export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
const maskConfigFunction: () => Partial<IConfig> = () => {
	return {
	  validation: false,
	};
  };
  
@NgModule({
	declarations: [
		NumberOnlyDirective,
		AppComponent,
		DashboardComponent,
		TamamlanmamisComponent,
		NavbarComponent,
		HomepageComponent,
		SolmenuComponent,
		SagmenuComponent,
		NewentryAdim1Component,
		EditentryAdim1Component,
		EditentryAdim2Component,
		EditentryAdim3Component,
		editentryadim4Component,
		editentryadim8Component,
		editentryadim9Component,
		editentryadim10Component,
		editentryadim11Component,
		FooterComponent,
		UcretlendirmeComponent,
		BankaHesapBilgileriComponent,
		HesapbilgileriComponent,
		CalismaAlaniComponent,
		HtmlPipe,
	],
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot(),
		LaddaModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		QuillModule.forRoot(),
		FileUploadModule,
		SweetAlert2Module.forRoot(),
		NgbModule,
		AppRoutingModule,
		NgxMaskModule.forRoot(maskConfigFunction),
		NgSelectModule
	],
	providers: [
		Globals,
		MessageServiceService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
