import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { MessageServiceService } from '../services/message-service.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-tamamlanmamis',
	templateUrl: './tamamlanmamis.component.html',
	styleUrls: ['./tamamlanmamis.component.css']
})
export class TamamlanmamisComponent implements OnInit {
	PageData: any;
	yuklendi = 0;
	subscription: Subscription;
	DosyaSilSwal = {
		title: 'Emin misiniz?',
		text: 'Başvurunuzu Silmek Üzeresiniz. Silinen projeyi geri alamazsınız.',
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, sil!',
		cancelButtonText: 'İptal'
	};

	constructor(
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {
	}

	ngOnInit() {
		window.scrollTo(0, 0);
		let that = this;
		that.ProjeSay();
		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'ProjeGuncellendi') {
				that.ProjeSay();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ProjeSay() {
		this.zone.run(() => {
			this.PageData = [];
			this.globals.projeler.forEach(element => {
				if (element.Adim11Durum === 0 && element.Deleted === 0) {
					this.PageData.push(element);
				}
			});

		});
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	ProjeSil(EntryId) {

		const UpdateData = {
			Id: EntryId
		}
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeSil', UpdateData, { params, headers }).subscribe(
			(response) => {
				const index = this.globals.projeler.findIndex(elem => elem.Id === EntryId);
				// console.log(index);
				this.globals.projeler.splice(index, 1);
				// this.ProjeSay();
				this.messageService.sendMessage('ProjeGuncellendi');
				this.showSuccess("Projeniz silindi.");
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

}
