import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-newentryadim1',
	templateUrl: './newentryadim1.component.html',
	styleUrls: ['./newentryadim1.component.css']
})
export class NewentryAdim1Component implements OnInit {
	Kategoriler: any;
	GlobalYonetim: any;
	subscription: Subscription;
	// PageData: any;
	// bullet = {
	// 	Kategori: '',
	// 	SubKategori: ''
	// }
	Yil = '2025';
	basliklar: any;
	SelectedSubKatData: any;
	SelectedKatIndex = -1;
	SelectedSubKatIndex = -1;
	Yuklendi = false;
	constructor(
		private http: HttpClient,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
	) {

	}

	ngOnDestroy() {


		this.subscription.unsubscribe();
	}

	ngOnInit() {
		if (this.globals.Yonetim.Yuklendi === true) {
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
			// console.log(this.Kategoriler);
			this.Yuklendi = true;
			window.scrollTo(0, 0);
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				this.Kategoriler = this.globals.YilaGoreKategoriAl(this.Yil);
				// console.log("bbb " + this.Yil);
				// console.log(this.Kategoriler);
				this.Yuklendi = true;
				window.scrollTo(0, 0);
			}
		});
	}

	KategoriChanged(Index) {
		this.SelectedKatIndex = Index;
		this.Kategoriler.forEach(element => {
			element.Selected = false;
		});
		this.Kategoriler[Index]['Selected'] = true;
		this.SelectedSubKatData = this.Kategoriler[Index]['SubKategoriler'];
		this.SelectedSubKatIndex = -1;
		console.log(this.SelectedSubKatData);
	}

	SubKategoriChanged(Index) {
		this.SelectedSubKatIndex = Index;
		this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'].forEach(element => {
			element.Selected = false;
		});
		this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][Index]['Selected'] = true;
		// this.SelectedSubKatData = this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'];
		// this.Kategoriler[Index]['Surdurulebilir'] = this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][Index]['Surdurulebilir'];
		// this.Hazir = true;
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	Kaydet() {
		
		var tarih = new Date();
		if (this.SelectedKatIndex === -1) {
			this.showWarning('Kategori Seçiniz.');
			return;
		}
		if (this.SelectedSubKatIndex === -1) {
			this.showWarning('Alt Kategori Seçiniz.');
			return;
		}

		let UpdateData;
		// console.log(this.Kategoriler[this.SelectedKatIndex])
		
		// console.log(that.PageData[that.SelectedKatIndex]['SubKategoriler'][that.SelectedSubKatIndex]['Surdurulebilir']);
		UpdateData = {
			'KategoriId': this.Kategoriler[this.SelectedKatIndex]['Id'],
			'SubKategoriId': this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][this.SelectedSubKatIndex]['Id'],
			'Surdurulebilir': this.Kategoriler[this.SelectedKatIndex]['SubKategoriler'][this.SelectedSubKatIndex]['Surdurulebilir']
		}
		console.log(UpdateData)
		// return;
		

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeYeni', UpdateData, { params, headers }).subscribe(
			(response) => {
				this.showSuccess("İlk adım kaydedildi, yönlendiriliyorsunuz.");
				this.globals.projeler.push(response);
				this.messageService.sendMessage('ProjeGuncellendi');
				// UpdateData.Id = response["ProjeId"];
				
				// console.log(this.GlobalYonetim)
				this.router.navigateByUrl('/editentry/' + response["Id"] + '/2');
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

}
