<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <header class="dev-entrant-layout">
                <h1>Tamamlanmamış Başvurular</h1>
                <div style="margin-top: 10px;">
                    <ul>
                        <li>Bu sayfada <strong>tamamlanmamış</strong> başvurularınız listelenmektedir.</li>
                        <li>Yeni Başvuru oluşturduğunuzda, başvuru sürecinizde eksik bıraktığınız adımları görebilirisiniz.</li>
                        <li>Başvuru ID’nize veya Kampanya Adı’nıza tıklayarak başvurunuzu düzenleyebilirsiniz.</li>
                        <li>Başvurunuzu tamamladıktan sonra sistem otomatik olarak yaptığınız başvuruyu “Tamamlanmış” alanına gönderecektir.</li>
                        <li>Eğer başvurunuzu <strong>tamamen silmek isterseniz</strong> “Sil” butonunu kullanabilirsiniz. Ancak, başvurunuzu sildiğiniz noktada girmiş olduğunuz bütün veriler sistemden silinecektir.
                            <strong>Silme işlemini geri almak mümkün değildir.</strong></li>
                    </ul>
                </div>
            </header>
            <!-- {{PageData | json}} -->
            <table class="tbl-entrant-entry-listing" *ngFor="let item of PageData; let i = index;">
                <tbody>
                    <tr style="height: 20px;">
                        <td rowspan="5" style="padding-top: 0px;padding-bottom: 0px;"><a [routerLink]="['/editentry/',item.Id,1]"><strong>#{{item.Id}}</strong></a></td>
                        <td colspan="10" style="padding-top: 0px;padding-bottom: 0px;"><a [routerLink]="['/editentry/',item.Id,1]"><strong>{{item.MarkaAdi}}<span *ngIf="!item.MarkaAdi">Marka Adı Belirtilmemiş</span></strong></a>
                        </td>
                        <td style="padding-top: 0px;padding-bottom: 0px;width: 100px;">
                            <button class="SilButton" [swal]="DosyaSilSwal" (confirm)="ProjeSil(item.Id)">Sil</button>
                        </td>
                    </tr>
                    <tr style="height: 20px;">
                        <td colspan="11" style="padding-top: 0px;padding-bottom: 0px;">{{item.KampanyaAdi}}<span *ngIf="!item.KampanyaAdi">Kampanya Adı Belirtilmemiş</span></td>
                    </tr>
                    <tr style="height: 20px;">
                        <td colspan="11" style="padding-top: 0px;padding-bottom: 0px;">{{item.Kategori}} - {{item.SubKategori}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;">Kategori Seçimi</td>
                        <td style="text-align: center;">Künye</td>
                        <td style="text-align: center;">Hedef</td>
                        <td style="text-align: center;">Fikir</td>
                        <td style="text-align: center;">Uygulama</td>
                        <td style="text-align: center;">Sonuç</td>
                        <td style="text-align: center;">Dosya Yükleme</td>
                        <td style="text-align: center;">Sayfa Sayısı Doğrulama</td>
                        <td style="text-align: center;">İzinler Belgesi</td>
                        <td style="text-align: center;">Başvur</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim1Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim1Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim2Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim2Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim4Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim4Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim5Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim5Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim6Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim6Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim7Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim7Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim8Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim8Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim9Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim9Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim10Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim10Durum === 0"></i>
                        </td>
                        <td style="text-align: center;">
                            <i class="fa fa-check" style="color: green;" *ngIf="item.Adim11Durum === 1"></i>
                            <i class="fa fa-times" style="color: red;" *ngIf="item.Adim11Durum === 0"></i>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </article>
    </div>
</section>