import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { MessageServiceService } from '../services/message-service.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	PageData: any;
	yuklendi = 0;
	subscription: Subscription;
	Dosyalar = []
	DosyaSilSwal = {
		title: 'Emin misiniz?',
		text: "Başvurunuzu Silmek Üzeresiniz. Silinen projeyi geri alamazsınız.",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, sil!',
		cancelButtonText: 'İptal'
	};
	SelectedBasvuruId = '';

	constructor(
		private toastr: ToastrService,
		private globals: Globals,
		private http: HttpClient,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private modalService: NgbModal,
	) {

	}

	ngOnInit() {
		window.scrollTo(0, 0);
		let that = this;
		that.ProjeSay();
		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'ProjeGuncellendi') {
				that.ProjeSay();
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ProjeSay() {
		this.zone.run(() => {
			this.PageData = [];
			this.globals.projeler.forEach(element => {
				if (element.Adim11Durum === 1) {
					this.PageData.push(element);
				}
			});
			console.log(this.PageData);
		});
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	ProjeSil(EntryId) {

		const UpdateData = {
			Id: EntryId
		}
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeSil', UpdateData, { params, headers }).subscribe(
			(response) => {
				const index = this.globals.projeler.findIndex(elem => elem.Id === EntryId);
				// console.log(index);
				this.globals.projeler.splice(index, 1);
				// this.ProjeSay();
				this.messageService.sendMessage('ProjeGuncellendi');
				this.showSuccess("Projeniz silindi.");
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	DosyalariGoster(content, Id) {
		this.SelectedBasvuruId = Id;
		this.Dosyalar = [];
		const index = this.PageData.findIndex(elem => elem.Id === Id);
		this.Dosyalar = this.PageData[index]['Adim8'];
		this.Dosyalar.forEach(element => {
			element.Tarih = element.Tarih.replace(' ', 'T');
		});

		this.modalService.open(content, { size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});

		// }).catch(function (error) {
		// 	that.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
		// });


	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}

	HTTPControl(link: string) {
		if (!link.includes("http")) {
			link = 'https://' + link;
		}
		return link;
	}
}
