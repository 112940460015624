import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateNativeAdapter, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim10',
	templateUrl: './editentryadim10.component.html',
	styleUrls: ['./editentryadim10.component.css']
})
export class editentryadim10Component implements OnInit {
	Yil = '';
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	PageData: any;
	Dosyalar = [];
	Yuklendi = false;
	selectedFile: any;
	downloadURL: Observable<string>;
	_returnedURL = '';
	// uploadProgress: Observable<number>;
	uploadProgress: 0;
	isLoading = false;
	Onay1 = false;
	Onay2 = false;
	Onay3 = false;

	uploader: FileUploader = new FileUploader({
		url: 'https://api.effieturkiye.org/ProjeIzinler',
		isHTML5: true,
		removeAfterUpload: true,
		autoUpload: false,
		allowedMimeType: ['application/pdf',
			'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/ms-doc', 'application/doc'	
		],
	});

	response: string;
	DosyaSilSwal = {
		title: 'Emin misiniz?',
		text: "Dosya Silmek Üzeresiniz",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	hasBaseDropZoneOver = false;
	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			// console.log("asdas");
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			// console.log("asdas2");
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				// console.log(this.GlobalYonetim);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});

		this.uploader.response.subscribe(res => this.response = res);
		this.uploader.authToken = 'Bearer ' + this.globals.User.Token;
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('ProjeId', this.EntryId);
			form.append('Authorization', 'Bearer ' + this.globals.User.Token);
		};

		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; console.log("dosya eklendi"); };
		this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
		this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
		this.uploader.onProgressItem = (progress: any) => {
			this.uploadProgress = progress['progress'];
		};
	}

	onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		let data = JSON.parse(response); //success server response
		// console.log(data);
		const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
		this.globals.projeler[index] = data;
		this.showSuccess("Dosya eklendi.");
		this.modalService.dismissAll('');
		this.selectedFile = null;
		this.isLoading = false;
		this.uploadProgress = 0;
		// this.uploader.queue = [];
		// this.GetData();
		this.DataYukle();
	}

	onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		this.showError("Dosya yükleme iptal edildi");
		this.modalService.dismissAll('');
		this.isLoading = false;
		let error = JSON.parse(response); //error server response
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			// console.log(this.PageData);
			if (this.PageData.Adim11Durum === 1) {
				this.zone.run(() => {
					this.router.navigateByUrl('/dashboard');
				});
			}

			this.Dosyalar = [];
			this.PageData['Adim10'].forEach(element => {
				element.Tarih = element.Tarih.replace(' ', 'T');
				this.Dosyalar.push(element);
			});
			this.Yuklendi = true;
			// setTimeout(() => {
			// 	this.uploader.queue = [];	
			// }, 500);
			
			// this.uploader.clearQueue();
			// this.DataDetay();

		}
	}

	TarihFormatla(Tarih) {
		var options = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {

			
			let UpdateData = {
				'IpsosIzin': 1,
				'EbookIzin': this.PageData.EbookIzin,
				'GenelIzin': this.PageData.GenelIzin,
				'ProjeId': this.EntryId
			};
			// console.log(UpdateData);
			// return;

			const params = new HttpParams();
			let headers = new HttpHeaders();
			headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
			headers = headers.append('Content-Type', 'application/json');
			headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

			this.http.post('https://api.effieturkiye.org/ProjeIpsosIzinleri', UpdateData, { params, headers }).subscribe(
				(response) => {
					const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
					this.globals.projeler[index] = response;
					this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
				},
				error => {
					//  console.log('Error', error)
					this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
				}
			);
		}
		else {
			this.showError("Bir hata oluştu.");
		}
	}

	ModalOpen(content) {
		this.selectedFile = null;
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'lg'
		};

		this.modalService.open(content, ngbModalOptions).result.then((result) => {

		}, (reason) => {

		});
	}

	DosyaModalSave() {
		if (this.selectedFile == null) {
			this.showWarning("Dosya Seçiniz.");
			return;
		}
		this.isLoading = true;
		this.uploader.options.additionalParameter = {
			Type: this.selectedFile.type,
			Boyut: this.selectedFile.size
		};
		this.uploader.uploadAll();
	}

	FileChange(event: any): void {
		this.selectedFile = event.target.files[0];
		if (this.selectedFile.size > 308908710) {
			this.showWarning("Dosya boyutu 300 mb dan büyük.");
			this.modalService.dismissAll('');
			this.selectedFile = null;
			this.uploader.clearQueue();
		}
	}

	DosyaSil(Id) {

		const UpdateData = {
			Id: Id,
			ProjeId: this.EntryId
		}

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeIzinlerSil', UpdateData, { params, headers }).subscribe(
			(response) => {
				const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
				this.globals.projeler[index] = response;
				this.DataYukle();
				this.showSuccess("Dosya silindi.");
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}

	EbookIzinChanged(val: boolean) {
		this.PageData.EbookIzin = val;
	}

	GenelIzinChanged(val: boolean) {
		this.PageData.GenelIzin = val;
	}
}
