<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register" action="#" method="post" *ngIf="Yuklendi === true && PageData.Adim11Durum === 0">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Hedef</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Fikir</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Uygulama</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sonuç</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim10Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(11)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                                <span class="badge" *ngIf="PageData.Adim11Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">
                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    
                                    <p *ngIf="GlobalYonetim.Adim10.Header.length > 0" [innerHtml]="GlobalYonetim.Adim10.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>
                                    <br>

                                    <table class="Table">
                                        <thead>
                                            <tr>
                                                <th>Adı</th>
                                                <th>Türü</th>
                                                <th>Boyutu</th>
                                                <th>Tarihi</th>
                                                <th style="text-align:center;">Ön İzleme</th>
                                                <th style="text-align:center;">Sil</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="Dosyalar.length === 0">
                                                <td colspan="6" align="center">Yüklenmiş dosyanız bulunmamaktadır.</td>
                                            </tr>
                                            <tr *ngFor="let item of Dosyalar; let i = index;">
                                                <td>{{item.DosyaAdi}}</td>
                                                <td>{{DosyaType(item.Type)}}</td>
                                                <td>{{formatBytes(item.Boyut)}}</td>
                                                <td>{{item.Tarih | date:'dd.MM.yyyy HH:mm'}}</td>
                                                <td align="center"><a href="https://api.effieturkiye.org/storage/izinler/{{EntryId}}/{{item.DosyaAdi}}" target="_blank" style="color: rgb(177, 152, 92);"><i
                                                            class="fas fa-eye"></i></a></td>
                                                <td align="center"><i class="fas fa-trash-alt" [swal]="DosyaSilSwal" (confirm)="DosyaSil(item.Id)" style="color: rgb(177, 152, 92); cursor: pointer;"></i></td>
                                            </tr>
                                        </tbody>
                                        <tfoot *ngIf="Dosyalar.length < 1">
                                            <tr>
                                                <td colspan="6">
                                                    <button class="EkleButton" (click)="ModalOpen(UploadModal)">Dosya
                                                        Ekle <img src="../../assets/arti.png" class="arti"
                                                            style="right: initial; margin-top: 5px; top: inherit;" /></button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <br>
                                    <div class="col-12">
                                        <label class="form-check-label CheckboxTitle">1. Effie Türkiye 2025  Yarışmasına Katılım İzni</label>
                                    </div>
                                    <div style="background-color: white; float: left; padding-left:0px; width: 80px;">
                                        <div class="form-check" (click)="GenelIzinChanged(true)">
                                            <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="PageData.GenelIzin == true"></i>
                                            <i class="far fa-circle" style="margin-right: 10px; font-size: 14px;" *ngIf="PageData.GenelIzin == false"></i>
                                            <label class="form-check-label CheckboxTitle">
                                                Evet
                                            </label>
                                        </div>
                                    </div>
                                    <div style="background-color: white; float: left; padding-left:0px; width: 80px;">
                                        <div class="form-check" (click)="GenelIzinChanged(false)">
                                            <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="PageData.GenelIzin == false"></i>
                                            <i class="far fa-circle" style="margin-right: 10px; font-size: 14px;" *ngIf="PageData.GenelIzin == true"></i>
                                            <label class="form-check-label CheckboxTitle">
                                                Hayır
                                            </label>
                                        </div>
                                    </div>
                                    <br><br>
                                    <div class="col-12">
                                        <label class="form-check-label CheckboxTitle">2. Effie Türkiye 2025  E-book Yayın İzni</label>
                                    </div>
                                    <div style="background-color: white; float: left; padding-left:0px; width: 80px;">
                                        <div class="form-check" (click)="EbookIzinChanged(true)">
                                            <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="PageData.EbookIzin == true"></i>
                                            <i class="far fa-circle" style="margin-right: 10px; font-size: 14px;" *ngIf="PageData.EbookIzin == false"></i>
                                            <label class="form-check-label CheckboxTitle">
                                                Evet
                                            </label>
                                        </div>
                                    </div>
                                    <div style="background-color: white; float: left; padding-left:0px; width: 80px;">
                                        <div class="form-check" (click)="EbookIzinChanged(false)">
                                            <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="PageData.EbookIzin == false"></i>
                                            <i class="far fa-circle" style="margin-right: 10px; font-size: 14px;" *ngIf="PageData.EbookIzin == true"></i>
                                            <label class="form-check-label CheckboxTitle">
                                                Hayır
                                            </label>
                                        </div>
                                    </div>
                                    <br><br>                                    
                                  
                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(9)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(11)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
    <ng-template #UploadModal let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Dosya Ekle</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row NoMarginLeftRight">
                <label class="col-md-3 label-control" for="KatkidaBulunanYetkililer">
                    <span class="VerticalAlignSub">Dosya:</span>
                    <span class="gerekli"> *</span>
                </label>
                <div class="col-md-9">
                    <input type="file" name="file[]" ng2FileSelect (change)="FileChange($event)" [uploader]="uploader"  accept=".doc, .pdf, .docx" />
                </div>
            </div>
            <div class="progress">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress) + '%'" [attr.aria-valuenow]="(uploadProgress)" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-block btn-raised white float-left ModalKapat" (click)="c('')">Kapat</button>
            <button type="button" [ladda]="isLoading" class="btn btn-block btn-raised white float-right ModalEkle" (click)="DosyaModalSave()">Ekle</button>
        </div>
    </ng-template>
</section>