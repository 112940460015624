import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	User = {
		Token: '',
		Decoded: {
			Basvuru: 1,
			IsAdmin: 0,
			IsEditor: 0,
			UserId: 0,
			iss: "effieturkiye.org",
			AdSoyad: ''
		}
	};

	projeler: any = [];
	Kategoriler: any = [];
	Kategoriler2025: any = [];
	Kategoriler2024: any = [];
	Kategoriler2023: any = [];
	Kategoriler2022: any = [];
	Sorular: any = [];
	SorularSurdurulebilir: any = [];
	Yonetim =
		{
			Adim1: [],
			Adim2: {},
			Adim3: [],
			Adim3Surdurulebilir: [],
			Adim4: [],
			Adim4Surdurulebilir: [],
			Adim5: [],
			Adim5Surdurulebilir: [],
			Adim6: [],
			Adim6Surdurulebilir: [],
			GenelBilgiler: '',
			Yuklendi: false,
			CalismaAlani: '',
			BankaHesapBilgileri: '',
			Ucretlendirme: ''
		};
	Yonetim2025: any;
	Yonetim2024: any;
	Yonetim2023: any;
	Yonetim2022: any;

	MailApproved: boolean = false;

	Sifirla() {
		this.User = {
			Token: '',
			Decoded: {
				Basvuru: 1,
				IsAdmin: 0,
				IsEditor: 0,
				UserId: 0,
				iss: 'effieturkiye.org',
				AdSoyad: ''
			}
		};

		this.projeler = [];
		this.Yonetim =
		{
			Adim1: [],
			Adim2: {},
			Adim3: [],
			Adim3Surdurulebilir: [],
			Adim4: [],
			Adim4Surdurulebilir: [],
			Adim5: [],
			Adim5Surdurulebilir: [],
			Adim6: [],
			Adim6Surdurulebilir: [],
			GenelBilgiler: '',
			Yuklendi: false,
			CalismaAlani: '',
			BankaHesapBilgileri: '',
			Ucretlendirme: ''
		};
		this.MailApproved = false;
	}

	YilaGoreGlobalYonetimAl(Yil: string): any {
		if (Yil == '2025') {
			return this.Yonetim2025;
		}
		else if (Yil == '2024') {
			return this.Yonetim2024;
		}
		else if (Yil == '2023') {
			return this.Yonetim2023;
		}
		else if (Yil == '2022') {
			return this.Yonetim2022;
		}
		else {
			return [];
		}
	}
	YilaGoreKategoriAl(Yil: string): any {
		if (Yil == '2025') {
			return this.Kategoriler2025;
		}
		else if (Yil == '2024') {
			return this.Kategoriler2024;
		}
		else if (Yil == '2023') {
			return this.Kategoriler2023;
		}
		else if (Yil == '2022') {
			return this.Kategoriler2022;
		}
		else {
			return [];
		}
	}
}