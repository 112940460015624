import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, ParsedResponseHeaders } from "ng2-file-upload";
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbDateAdapter, NgbDateNativeAdapter, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../providers/globals/globals';

import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';

@Component({
	selector: 'app-editentryadim8',
	templateUrl: './editentryadim8.component.html',
	styleUrls: ['./editentryadim8.component.css']
})
export class editentryadim8Component implements OnInit {
	Yil = '';
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	PageData: any;
	Dosyalar = [];
	Yuklendi = false;
	selectedFile: any;
	downloadURL: Observable<string>;
	_returnedURL = '';
	// uploadProgress: Observable<number>;
	uploadProgress: 0;
	isLoading = false;
	link = '';

	uploader: FileUploader = new FileUploader({
		url: 'https://api.effieturkiye.org/ProjeFiles',
		isHTML5: true,
		removeAfterUpload: true,
		autoUpload: false,
		// maxFileSize: 10 * 1024
		// * 1024
		// allowedMimeType: ['image/jpeg', 'image/png', 'video/mp4']
	});

	response: string;
	DosyaSilSwal = {
		title: 'Emin misiniz?',
		text: "Dosya Silmek Üzeresiniz",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Evet, silinsin!',
		cancelButtonText: 'İptal'
	};

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private modalService: NgbModal,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	hasBaseDropZoneOver = false;
	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			// console.log("asdas");
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			// console.log("asdas2");
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				// console.log(this.GlobalYonetim);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});

		this.uploader.response.subscribe(res => this.response = res);
		this.uploader.authToken = 'Bearer ' + this.globals.User.Token;
		this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
			form.append('ProjeId', this.EntryId);
			form.append('Authorization', 'Bearer ' + this.globals.User.Token);
		};

		this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
		this.uploader.onErrorItem = (item, response, status, headers) => { this.onErrorItem(item, response, status, headers); };
		this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
		this.uploader.onProgressItem = (progress: any) => {
			this.uploadProgress = progress['progress'];
		};

	}

	onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		this.uploadProgress = 0;
		let data = JSON.parse(response); //success server response
		// console.log(data);
		const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
		this.globals.projeler[index] = data;
		this.showSuccess("Dosya eklendi.");
		this.modalService.dismissAll('');
		this.selectedFile = null;
		this.isLoading = false;
		// this.GetData();
		this.DataYukle();
	}

	onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
		this.showError("Dosya yükleme iptal edildi");
		this.modalService.dismissAll('');
		this.selectedFile = null;
		this.isLoading = false;
		let error = JSON.parse(response); //error server response
	}

	DataYukle() {
		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {

			this.PageData = JSON.parse(JSON.stringify(result[0]));

			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);

			if (this.PageData.Adim11Durum === 1) {
				this.zone.run(() => {
					this.router.navigateByUrl('/dashboard');
				});
			}
			this.Dosyalar = [];
			this.PageData['Adim8'].forEach(element => {
				element.Tarih = element.Tarih.replace(' ', 'T');
				this.Dosyalar.push(element);
			});
			this.Yuklendi = true;
			// this.DataDetay();
		}
	}

	TarihFormatla(Tarih) {
		var options = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		if (typeof sayfa !== 'undefined') {
			this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
		}
		else {
			this.showError("Bir hata oluştu.");
		}
	}


	ModalOpen(content) {
		this.selectedFile = null;
		let ngbModalOptions: NgbModalOptions = {
			backdrop: 'static',
			keyboard: false,
			size: 'lg'
		};

		this.modalService.open(content, ngbModalOptions).result.then((result) => {
		}, (reason) => {
		});
	}

	DosyaModalSave() {
		if (this.selectedFile === null) {
			this.showWarning("Dosya Seçiniz.");
			return;
		}
		this.isLoading = true;
		this.uploader.options.additionalParameter = {
			Type: this.selectedFile.type,
			Boyut: this.selectedFile.size
		};
		this.uploader.uploadAll();
	}

	LinkModalSave() {
		if (this.link === '') {
			this.showWarning("Url giriniz.");
			return;
		}
		this.isLoading = true;
		const UpdateData = {
			Url: this.link,
			ProjeId: this.EntryId
		}

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeLinkEkle', UpdateData, { params, headers }).subscribe(
			(response) => {
				this.link = '';
				const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
				this.globals.projeler[index] = response;
				this.DataYukle();
				this.modalService.dismissAll('');
				this.showSuccess("Link Eklendi.");
				this.isLoading = false;
			},
			error => {
				//  console.log('Error', error)
				this.isLoading = false;
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);


	}

	FileChange(event: any): void {
		// console.log(event.target.files)
		// console.log(this.uploader.queue)
		if(this.uploader.queue.length>1)
		{
			this.uploader.queue.splice(0, 1);
		}
		// this.selectedFile = null;
		// this.uploader.clearQueue();
		this.selectedFile = event.target.files[0];
		// console.log(this.selectedFile)
		if (this.selectedFile.size > 308908710) {
			this.showWarning("Dosya boyutu 300 mb dan büyük.");
			this.modalService.dismissAll('');
			this.selectedFile = null;
			this.uploader.clearQueue();
		}
	}


	DosyaSil(Id) {

		const UpdateData = {
			Id: Id,
			ProjeId: this.EntryId
		}

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/ProjeFilesSil', UpdateData, { params, headers }).subscribe(
			(response) => {
				const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
				this.globals.projeler[index] = response;
				this.DataYukle();
				this.showSuccess("Dosya silindi.");
			},
			error => {
				//  console.log('Error', error)
				this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	slugify(text) {
		var trMap = {
			'çÇ': 'c',
			'ğĞ': 'g',
			'şŞ': 's',
			'üÜ': 'u',
			'ıİ': 'i',
			'öÖ': 'o',
			' ': '_',
			'?': '_',
			'-': '_',
			'&': '_'
		};
		for (var key in trMap) {
			text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
		}
		return text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
			.replace(/\s/gi, "-") // convert spaces to dashes
			.replace(/[-]+/gi, "-") // trim repeated dashes
			.toLowerCase();

	}

	DosyaType(text) {
		if (text === 'text/plain') {
			text = "txt"
		}
		else if (text === "application/msword") {
			text = "Word"
		} else if (text === "image/jpeg") {
			text = "Resim"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}

	HTTPControl(link: string) {
		if (!link.includes("http")) {
			link = 'https://' + link;
		}
		return link;
	}
}
