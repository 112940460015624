<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register" action="#" method="post" *ngIf="Yuklendi === true && PageData.Adim11Durum === 0">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Hedef</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Fikir</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Uygulama</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sonuç</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(10)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim10Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(11)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                                <span class="badge" *ngIf="PageData.Adim11Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">
                                <!-- {{PageData | json}}  -->
                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <p *ngIf="GlobalYonetim.Adim3.Header.length > 0" [innerHtml]="GlobalYonetim.Adim3.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-12 label-control" style="color: white;" for="projectinput1">
                                                <div class="VerticalAlignSub PNoMargin" [innerHtml]="GlobalYonetim.Adim3.TarihBaslik"></div>
                                            </label>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.TarihBasBaslik"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style="color: white; text-align: center;">Gün</th>
                                                            <th style="color: white; text-align: center;">Ay</th>
                                                            <th style="color: white; text-align: center;">Yıl</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <select class="form-control" type="text" name="BasGun" [(ngModel)]="PageData.Adim3.BasGun">
                                                                    <option value="0">Seçiniz</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                    <option value="13">13</option>
                                                                    <option value="14">14</option>
                                                                    <option value="15">15</option>
                                                                    <option value="16">16</option>
                                                                    <option value="17">17</option>
                                                                    <option value="18">18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25">25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select class="form-control" type="text" name="BasAy" [(ngModel)]="PageData.Adim3.BasAy">
                                                                    <option value="0">Seçiniz</option>
                                                                    <option value="1">Ocak</option>
                                                                    <option value="2">Şubat</option>
                                                                    <option value="3">Mart</option>
                                                                    <option value="4">Nisan</option>
                                                                    <option value="5">Mayıs</option>
                                                                    <option value="6">Haziran</option>
                                                                    <option value="7">Temmuz</option>
                                                                    <option value="8">Ağustos</option>
                                                                    <option value="9">Eylül</option>
                                                                    <option value="10">Ekim</option>
                                                                    <option value="11">Kasım</option>
                                                                    <option value="12">Aralık</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select class="form-control" type="text" name="BasYil" [(ngModel)]="PageData.Adim3.BasYil">
                                                                    <option value="0">Seçiniz</option>
                                                                    <option value="2010">2010</option>
                                                                    <option value="2011">2011</option>
                                                                    <option value="2012">2012</option>
                                                                    <option value="2013">2013</option>
                                                                    <option value="2014">2014</option>
                                                                    <option value="2015">2015</option>
                                                                    <option value="2016">2016</option>
                                                                    <option value="2017">2017</option>
                                                                    <option value="2018">2018</option>
                                                                    <option value="2019">2019</option>
                                                                    <option value="2020">2020</option>
                                                                    <option value="2021">2021</option>
                                                                    <option value="2022">2022</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2028">2028</option>
                                                                    <option value="2029">2029</option>
                                                                    <option value="2030">2030</option>
                                                                    <option value="2031">2031</option>
                                                                </select>
                                                            </td>
                                                            <td class="p-2">


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.TarihBitBaslik"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <table>

                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <select class="form-control" type="text" name="BitGun" [(ngModel)]="PageData.Adim3.BitGun" [disabled]="PageData.Adim3.DevamEdiyor == 1">
                                                                    <option value="0">Seçiniz</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="11">11</option>
                                                                    <option value="12">12</option>
                                                                    <option value="13">13</option>
                                                                    <option value="14">14</option>
                                                                    <option value="15">15</option>
                                                                    <option value="16">16</option>
                                                                    <option value="17">17</option>
                                                                    <option value="18">18</option>
                                                                    <option value="19">19</option>
                                                                    <option value="20">20</option>
                                                                    <option value="21">21</option>
                                                                    <option value="22">22</option>
                                                                    <option value="23">23</option>
                                                                    <option value="24">24</option>
                                                                    <option value="25">25</option>
                                                                    <option value="26">26</option>
                                                                    <option value="27">27</option>
                                                                    <option value="28">28</option>
                                                                    <option value="29">29</option>
                                                                    <option value="30">30</option>
                                                                    <option value="31">31</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select class="form-control" type="text" name="BitAy" [(ngModel)]="PageData.Adim3.BitAy" [disabled]="PageData.Adim3.DevamEdiyor == 1">
                                                                    <option value="0">Seçiniz</option>
                                                                    <option value="1">Ocak</option>
                                                                    <option value="2">Şubat</option>
                                                                    <option value="3">Mart</option>
                                                                    <option value="4">Nisan</option>
                                                                    <option value="5">Mayıs</option>
                                                                    <option value="6">Haziran</option>
                                                                    <option value="7">Temmuz</option>
                                                                    <option value="8">Ağustos</option>
                                                                    <option value="9">Eylül</option>
                                                                    <option value="10">Ekim</option>
                                                                    <option value="11">Kasım</option>
                                                                    <option value="12">Aralık</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select class="form-control" type="text" placeholder="Web Sitesi" name="BitYil" [(ngModel)]="PageData.Adim3.BitYil" [disabled]="PageData.Adim3.DevamEdiyor == 1">
                                                                    <option value="2010">2010</option>
                                                                    <option value="2011">2011</option>
                                                                    <option value="2012">2012</option>
                                                                    <option value="2013">2013</option>
                                                                    <option value="2014">2014</option>
                                                                    <option value="2015">2015</option>
                                                                    <option value="2016">2016</option>
                                                                    <option value="2017">2017</option>
                                                                    <option value="2018">2018</option>
                                                                    <option value="2019">2019</option>
                                                                    <option value="2020">2020</option>
                                                                    <option value="2021">2021</option>
                                                                    <option value="2022">2022</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2028">2028</option>
                                                                    <option value="2029">2029</option>
                                                                    <option value="2030">2030</option>
                                                                    <option value="2031">2031</option>
                                                                </select>
                                                            </td>
                                                            <td class="p-2">
                                                                <span for="devam" style="color: white; text-align: center;"> Devam Ediyor</span>
                                                                <input class="form-control" type="checkbox" id="devam" name="devam" [(ngModel)]="PageData.Adim3.DevamEdiyor" style="height: auto!important;" (ngModelChange)="DevamEdiyorChange($event)">

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                    <p style="max-width: none; width: 100%; margin-bottom: 10px"></p>

                                    <div class="form-body">
                                        <div class="form-group row NoMarginLeftRight" style="background-color: #fff;">
                                            <div class="col-md-12 PNoMargin" [innerHtml]="GlobalYonetim.Adim3.Satir1"></div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.Satir2"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" name="Satir2" [(ngModel)]="PageData.Adim3.Satir2" (ngModelChange)="KarakterSayici($event, 'Satir2')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir2WordCount > 30}">Kelime Sayısı: {{PageData.Adim3.Satir2WordCount}}/30</div>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.Satir3"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" name="Satir3" [(ngModel)]="PageData.Adim3.Satir3" (ngModelChange)="KarakterSayici($event, 'Satir3')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir3WordCount > 30}">Kelime Sayısı: {{PageData.Adim3.Satir3WordCount}}/30</div>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.Satir4"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" name="Satir4" [(ngModel)]="PageData.Adim3.Satir4" (ngModelChange)="KarakterSayici($event, 'Satir4')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir4WordCount > 30}">Kelime Sayısı: {{PageData.Adim3.Satir4WordCount}}/30</div>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.Satir5"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" name="Satir5" [(ngModel)]="PageData.Adim3.Satir5" (ngModelChange)="KarakterSayici($event, 'Satir5')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir5WordCount > 30}">Kelime Sayısı: {{PageData.Adim3.Satir5WordCount}}/30</div>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <label class="col-md-3 label-control" style="color: white;" for="KampanyaAdi">
                                                <div class="PNoMargin VerticalAlignSub" style="padding-top: 3px;" [innerHtml]="GlobalYonetim.Adim3.Satir6"></div>
                                            </label>
                                            <div class="col-md-9">
                                                <textarea class="form-control" name="Satir6" [(ngModel)]="PageData.Adim3.Satir6" (ngModelChange)="KarakterSayici($event, 'Satir6')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir6WordCount > 30}">Kelime Sayısı: {{PageData.Adim3.Satir6WordCount}}/30</div>
                                            </div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight" style="background-color: #fff;">
                                            <div class="col-md-12 PNoMargin" [innerHtml]="GlobalYonetim.Adim3.Satir7"></div>
                                        </div>
                                        <div class="form-group row NoMarginLeftRight">
                                            <div class="col-md-12">
                                                <textarea class="form-control" name="Satir8" [(ngModel)]="PageData.Adim3.Satir8" (ngModelChange)="KarakterSayici($event, 'Satir8')"></textarea>
                                                <div class="KarakterSayici" [ngClass]="{'kirmizi': PageData.Adim3.Satir8WordCount > 100}">Kelime Sayısı: {{PageData.Adim3.Satir8WordCount}}/100</div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(2)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(4)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>

</section>