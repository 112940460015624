import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[myNumberOnly]'
})
export class NumberOnlyDirective {
    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*)+(\(*)+(\)*)+(\-*){0,1,2,3,4}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
    // , '-'

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        console.log(event);
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        console.log(next, this.regex.test(next), this.regex);
        if (next && !String(next).match(this.regex)) {
            console.log('aloo')
            event.preventDefault();
        }
    }
}