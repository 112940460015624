<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                    </div>
                    <div class="form-register" action="#" method="post" *ngIf="Yuklendi === true && PageData.Adim11Durum === 0">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(4)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Hedef</span>
                                                <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(5)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Fikir</span>
                                                <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(6)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Uygulama</span>
                                                <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(7)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sonuç</span>
                                                <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(10)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim10Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(11)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                                <span class="badge" *ngIf="PageData.Adim11Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix">

                                <form class="form form-horizontal AdimliForm" novalidate="">
                                    <!-- {{PageData | json}} -->
                                    <p *ngIf="GlobalYonetim.Adim9.Header.length > 0" [innerHtml]="GlobalYonetim.Adim9.Header" style="max-width: none; width: 100%; margin-bottom: 10px"></p>
                                    <ng-container *ngIf="PageData.Adim9SayfaSayisi > 0">
                                        Son Güncelleme Tarihi: {{PageData.GuncellemeTarihi | date:'dd.MM.yyyy HH:mm'}}
                                        <br> Son PDF Güncelleme Tarihi: {{PageData.Adim9Tarih | date:'dd.MM.yyyy HH:mm'}} <br> Sayfa Sayısı: {{PageData.Adim9SayfaSayisi}}<br>
                                        <a *ngIf='PageData.Adim9SayfaSayisi > 0' href="https://pdfebook.effieturkiye.org/pdfler/{{PageData.Id}}/1.pdf" target="_blank">Sayfa Sayısı Doğrulama PDF'ini İndir</a><br>
                                        <a *ngIf='PageData.Adim9SayfaSayisi > 0' href="https://pdfebook.effieturkiye.org/pdfler/{{PageData.Id}}/2.pdf" target="_blank">Juri PDF'ini İndir</a>
                                    </ng-container>
                                    <div class="kirmizi" *ngIf="PageData.Adim1Durum === 0 || PageData.Adim2Durum === 0 || PageData.Adim3Durum === 0 || PageData.Adim4Durum === 0 || PageData.Adim5Durum === 0 || PageData.Adim6Durum === 0 || PageData.Adim7Durum === 0 || PageData.Adim8Durum === 0">
                                        PDF oluşturabilmek için 1-8 arasındaki adımları doldurmalısınız.
                                    </div>
                                    <div class="kirmizi" *ngIf="PageData.Surdurulebilir === 0 && PageData.Adim9Durum === 0 && PageData.Adim9SayfaSayisi > 7">
                                        SAYFA SAYINIZ 7 DEN FAZLA OLMUŞTUR LÜTFEN DÜZENLEYİP TEKRAR PDF OLUŞTURUNUZ.
                                    </div>
                                    <div class="kirmizi" *ngIf="PageData.Surdurulebilir === 1 && PageData.Adim9Durum === 0 && PageData.Adim9SayfaSayisi > 9">
                                        Sayfa sayınız 9 dan fazla olamaz.
                                    </div>
                                </form>

                                <button class="EkleButton" [ladda]="isLoading" *ngIf="PageData.Adim1Durum === 1 && PageData.Adim2Durum === 1 && PageData.Adim4Durum === 1 && PageData.Adim5Durum === 1 && PageData.Adim6Durum === 1 && PageData.Adim7Durum === 1 && PageData.Adim8Durum === 1"
                                    (click)="HtmlOlustur()">PDF Oluştur</button>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(8)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(10)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>