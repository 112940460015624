import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MessageServiceService } from '../services/message-service.service';
import { Globals } from '../providers/globals/globals';
import { ToastrService } from 'ngx-toastr';
import { LaddaModule } from 'angular2-ladda';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-homepage',
	templateUrl: './homepage.component.html',
	styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
	myform: FormGroup;
	isLoading: boolean = false;
	email = '';
	password = '';

	toggleLoading() {
		this.isLoading = !this.isLoading;
	}

	constructor(
		private router: Router,
		private toastr: ToastrService,
		fb: FormBuilder,
		private messageService: MessageServiceService,
		private globals: Globals,
		private http: HttpClient,
		private zone: NgZone,
	) {

	}

	ngOnInit() {
		this.myform = new FormGroup({
			email: new FormControl('email', [
				Validators.required,
				Validators.pattern("[^ @]*@[^ @]*")
			]),
			password: new FormControl('password', [
				Validators.minLength(4),
				Validators.required
			])
		});
	}


	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}


	guid(): string {
		var nav = window.navigator;
		var screen = window.screen;
		var guid = '';
		guid += nav.mimeTypes.length.toString();
		guid += nav.userAgent.replace(/\D+/g, '');
		guid += nav.plugins.length.toString();
		guid += screen.height || '';
		guid += screen.width || '';
		guid += screen.pixelDepth || '';
		return guid;
	};

}
