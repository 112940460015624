<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <header class="dev-entrant-layout">
                <h1>Banka Hesap Bilgisi</h1>
            </header>
            <div style="margin-top: 10px;" [innerHtml]="Icerik | htmlPipe">
                    
            </div>
        </article>
    </div>
</section>