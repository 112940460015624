<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Yeni Başvuru</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register" action="#" method="post" *ngIf="Yuklendi === true">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">4</span>
                                                <span class="step-text">Hedef</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">5</span>
                                                <span class="step-text">Fikir</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">6</span>
                                                <span class="step-text">Uygulama</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">7</span>
                                                <span class="step-text">Sonuç</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a>
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content clearfix AdimliForm">
                                <h3 style="color: rgb(177, 152, 92);">Sektörel veya Özel kategori seçimini yapınız.</h3>
                                <!-- <div>
                                    <p>
                                        <strong>Sektörel veya Özel kategori seçimini yapınız.</strong>
                                    </p>
                                    Bir kampanya ile en fazla bir sektörel kategori ve bir özel kategoriye başvurabilirsiniz.<br> Aynı kampanya ile bir sektörel ve bir özel kategoriye başvuru yaptığınız durumda, vakanızı her kategorinin tanımına göre
                                    özelleştirmeniz gerekmektedir.<br> Aynı kampanyayla birden fazla sektörel veya özel kategoriye katılmak mümkün değildir.
                                </div> -->
                                <div>
                                    Bir kampanya ile en fazla bir sektörel kategori ve bir özel kategoriye başvurabilirsiniz.<br> Bir kampanya ile bir sektörel ve bir özel kategori başvurusu yapabilirsiniz. Ancak bu durumda, vakanızı <u>her kategorinin tanımına göre özelleştirmeniz</u>,
                                    2 ayrı başvuru oluşturmanız gerekmektedir.<br> Aynı kampanya ile birden fazla sektörel veya özel kategoriye katılmak mümkün değildir.
                                </div>

                                <div id="category_filter" class="dev-category-finder dev-flex-container">
                                    <fieldset class="dev-attribute-set-1 dev-flex-child">
                                        <label class="dev-boolean" style="max-width: none; width: 100%;" *ngFor="let item of Kategoriler; let i = index;" (click)="KategoriChanged(i)" style="cursor: pointer;">
                                            <!-- {{item | json}} -->
                                            <!-- {{item.Selected}} -->
                                            <i class="far fa-check-circle" style="margin-right: 10px; color: green;"
                                                *ngIf="item.Selected"></i>
                                            <i class="far fa-circle" style="margin-right: 10px;"
                                                *ngIf="!item.Selected"></i>
                                            <span>
                                                {{item.Kategori}}<br>
                                                <em [innerHTML]="item.Aciklama | htmlPipe"></em>
                                            </span>
                                        </label>
                                    </fieldset>
                                </div>

                                <div class="actions clearfix" *ngIf="SelectedKatIndex > -1 && SelectedSubKatIndex > -1">
                                    <ul role="menu" class="Pagination" style="margin:0px;">
                                        <li class="disabled"><a role="menuitem">Bir Önceki Adım</a></li>
                                        <li (click)="Kaydet()"><a role="menuitem">Bir Sonraki Adım</a></li>
                                    </ul>
                                </div>

                                <div class="dev-category-available" *ngIf="SelectedSubKatData">
                                    <h3 style="padding-bottom: 0px; color: rgb(177, 152, 92);">Alt Kategorinizi Seçiniz
                                    </h3>
                                    <div class="dev-flex-container">
                                        <div class="dev-flex-child">
                                            <div id="category_list">
                                                <table class="tbl-category-selection MarginTop0" style="margin-bottom: 0em;">
                                                    <tbody>
                                                        <tr *ngFor="let item of SelectedSubKatData; let i = index;" (click)="SubKategoriChanged(i)" style="cursor: pointer;">

                                                            <td class="dev-choice">
                                                                <!-- {{item.Selected}} -->
                                                                <i class="far fa-check-circle" style="margin-right: 10px; color: green;" *ngIf="item.Selected"></i>
                                                                <i class="far fa-circle" style="margin-right: 10px;" *ngIf="!item.Selected"></i>
                                                            </td>
                                                            <td>
                                                                <p style="margin-bottom: 0rem;"><span class="dev-cat-name FontWeight500">{{i + 1}} -
                                                                        {{item.Kategori}}</span><br>
                                                                    <em [innerHTML]="item.Aciklama | htmlPipe"></em>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="actions clearfix" *ngIf="SelectedKatIndex > -1 && SelectedSubKatIndex > -1">
                                <ul role="menu" class="Pagination">
                                    <li class="disabled"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="Kaydet()"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>