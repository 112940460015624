<section id="SolMenu">
    <header>
        <h1>Sn. {{globals.User.Decoded.AdSoyad}}</h1>
        <!-- <p>Hesabım</p> -->
    </header>
    <a class="dev-button" [routerLink]="['/newentry']"><span>Yeni Başvuru</span></a>
    <nav id="user-entry-nav">
        <h6>Genel Bakış</h6>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li class="dev-active">
                <a [routerLink]="['/dashboard']">
                    <span>Tamamlanmış</span>
                    <span class="dev-count">{{tamamlanmis}}</span>
                </a>
            </li>
        </ul>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li>
                <a [routerLink]="['/tamamlanmamis']">
                    <span>Tamamlanmamış</span>
                    <span class="dev-count">{{tamamlanmamis}}</span>
                </a>
            </li>
        </ul>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li>
                <a [routerLink]="['/calismaalani']">
                    <span>Çalışma Dosyası</span>
                </a>
            </li>
        </ul>
        <h6>Ücretlendirme</h6>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li>
                <a [routerLink]="['/ucretlendirme']">
                    <span>Tarihler ve Ücretlendirme</span>
                    <!-- <span class="dev-count">1</span> -->
                </a>
            </li>
            <li>
                <a [routerLink]="['/bankahesapbilgileri']">
                    <span>Banka Hesap Bilgisi</span>
                    <!-- <span class="dev-count">1</span> -->
                </a>
            </li>
        </ul>
        <h6>Hesabım</h6>
        <ul class="mg-app-nav-menu dev-nav-progress">
            <li>
                <a [routerLink]="['/hesapbilgileri']">
                    <span>Hesap bilgileri</span>
                    <!-- <span class="dev-count">1</span> -->
                </a>
            </li>
            <li>
                <a (click)="Logout()">
                    <span>Çıkış</span>
                    <!-- <span class="dev-count">1</span> -->
                </a>
            </li>
        </ul>
    </nav>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</section>