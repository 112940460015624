import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { MessageServiceService } from '../services/message-service.service';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import swal from 'sweetalert2';

@Component({
	selector: 'app-editentryadim11',
	templateUrl: './editentryadim11.component.html',
	styleUrls: ['./editentryadim11.component.css']
})
export class editentryadim11Component implements OnInit {
	Yil = '';
	subscription: Subscription;
	GlobalYonetim: any;
	EntryId;
	PageData: any;
	Yuklendi = false;

	DosyaSilSwal = {
		title: 'Tüm soruları cevapladığınıza emin misiniz?',
		text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Devam',
		cancelButtonText: 'Vaka\'yı düzenle',
		denyButtonColor: '#59A3FF',
		showDenyButton: false,
		denyButtonText: 'Ödeme Sayfası',
	};

	constructor(

		private route: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
		private http: HttpClient,
	) {

	}

	OdemeSayfasiAc() {
		console.log("Ödeme")
		window.open("https://garanti.com.tr", "_blank");
	}

	hasBaseDropZoneOver = false;
	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.EntryId = params.get('EntryId');
		});

		if (this.globals.Yonetim.Yuklendi === true) {
			// console.log("asdas");
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			window.scrollTo(0, 0);
			this.DataYukle();
		}

		this.subscription = this.messageService.getMessage().subscribe(message => {
			// console.log("asdas2");
			if (message.text === 'Yonetim') {
				this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
				// console.log(this.GlobalYonetim);
				window.scrollTo(0, 0);
				this.DataYukle();
			}
		});
	}

	DataYukle() {

		var result = this.globals.projeler.filter(function (elem, i, rep) {
			return elem.Id === this;
		}, parseInt(this.EntryId));
		if (result.length) {
			this.PageData = JSON.parse(JSON.stringify(result[0]));
			if (this.PageData.Adim11Durum === 1) {
				this.zone.run(() => {
					this.router.navigateByUrl('/dashboard');
				});
			}
			this.Yil = this.PageData['Yil'];
			this.GlobalYonetim = this.globals.YilaGoreGlobalYonetimAl(this.Yil);
			this.Yuklendi = true;
			console.log(this.GlobalYonetim.Fiyatlar);
			let today = new Date();
			today.setHours(0, 0, 0, 0);
			let BirinciTarih = new Date(this.GlobalYonetim.Fiyatlar.BirinciTarih);
			BirinciTarih.setHours(0, 0, 0, 0);

			let IkinciTarih = new Date(this.GlobalYonetim.Fiyatlar.IkinciTarih);
			IkinciTarih.setHours(0, 0, 0, 0);

			let UcuncuTarih = new Date(this.GlobalYonetim.Fiyatlar.UcuncuTarih);
			UcuncuTarih.setHours(0, 0, 0, 0);
			// let bugun = new Date(today.getFullYear, )
			// console.log(today);
			// console.log(BirinciTarih);

			// if (today.getTime() == BirinciTarih.getTime()) {
			// 	console.log("date1 and date2 are the same");
			// } 

			if (today >= BirinciTarih && today < IkinciTarih) {
				this.PageData.Price = this.GlobalYonetim.Fiyatlar.BirinciFiyat;
			} else if (today >= IkinciTarih && today < UcuncuTarih) {
				this.PageData.Price = this.GlobalYonetim.Fiyatlar.IkinciFiyat;
			} else if (today >= UcuncuTarih) {
				this.PageData.Price = this.GlobalYonetim.Fiyatlar.UcuncuFiyat;
			}
			// console.log(this.PageData.Price);

		}
	}

	showSuccess(mesaj: string) {
		this.toastr.success(mesaj, 'Tebrikler!');
	}

	showError(mesaj: string) {
		this.toastr.error(mesaj, 'Hata!');
	}

	showWarning(mesaj: string) {
		this.toastr.warning(mesaj, 'Dikkat!');
	}

	showInfo(mesaj: string) {
		this.toastr.info(mesaj);
	}

	AdimDegistir(sayfa: number) {
		this.router.navigateByUrl('/editentry/' + this.EntryId + '/' + sayfa);
	}

	BasvuruGonder() {

		swal.fire({
			title: 'Vakanızda ajans adı geçmediğinden emin misiniz?',
			text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CC27E',
			cancelButtonColor: '#FF586B',
			confirmButtonText: 'Devam',
			cancelButtonText: "Vaka\'yı düzenle"
		}).then((isConfirm) => {
			// console.log(isConfirm);
			if (isConfirm.value) {
				swal.fire({
					title: 'Vakanızda herhangi bir kreatif görsel, markanın logosu vb. kullanmadığınızdan emin misiniz?',
					text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#0CC27E',
					cancelButtonColor: '#FF586B',
					confirmButtonText: 'Devam',
					cancelButtonText: "Vaka\'yı düzenle"
				}).then((isConfirm) => {
					// console.log(isConfirm);
					if (isConfirm.value) {
						swal.fire({
							title: 'Vakanızda sonuçlarınıza ilişkin kaynak gösterdiğinizden emin misiniz?',
							text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
							icon: 'warning',
							showCancelButton: true,
							confirmButtonColor: '#0CC27E',
							cancelButtonColor: '#FF586B',
							confirmButtonText: 'Devam',
							cancelButtonText: "Vaka\'yı düzenle"
						}).then((isConfirm) => {
							// console.log(isConfirm);
							if (isConfirm.value) {
								swal.fire({
									title: 'Vakanızda sözü edilen bütün yaratıcı işlerden örnekler koyduğunuza emin misiniz?',
									text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
									icon: 'warning',
									showCancelButton: true,
									confirmButtonColor: '#0CC27E',
									cancelButtonColor: '#FF586B',
									confirmButtonText: 'Devam',
									cancelButtonText: "Vaka\'yı düzenle"
								}).then((isConfirm) => {
									// console.log(isConfirm);
									if (isConfirm.value) {
										swal.fire({
											title: 'Emin misiniz?',
											text: 'Başvurunuzu tamamlamak üzeresiniz. Gönderdikten sonra değişiklik yapmak istediğiniz takdirde başvuruyu silip “Yeni Başvuru” oluşturmanız gerekmektedir.',
											icon: 'warning',
											showCancelButton: true,
											confirmButtonColor: '#0CC27E',
											cancelButtonColor: '#FF586B',
											confirmButtonText: 'Devam',
											cancelButtonText: "Vaka\'yı düzenle"
										}).then((isConfirm) => {
											// console.log(isConfirm);
											if (isConfirm.value) {
												swal.fire({
													title: 'Başvurunuza ait ödemeyi havale veya EFT ile yapabilirsiniz.',
													text: 'Detaylı bilgi için ana sayfadaki Banka Hesap Bilgisi menüsünü inceleyebilirsiniz.',
													icon: 'warning',
													showCancelButton: true,
													confirmButtonColor: '#0CC27E',
													cancelButtonColor: '#FF586B',
													confirmButtonText: 'Devam',
													cancelButtonText: "Vaka\'yı düzenle"
												}).then((isConfirm) => {
													// console.log(isConfirm);
													if (isConfirm.value) {
														const UpdateData = {
															Id: this.EntryId,
															Price: this.PageData.Price,
														}
														// console.log(JSON.stringify(UpdateData));

														const params = new HttpParams();
														let headers = new HttpHeaders();
														headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
														headers = headers.append('Content-Type', 'application/json');
														headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

														this.http.post('https://api.effieturkiye.org/ProjeBasvuruyuGonder', UpdateData, { params, headers }).subscribe(
															(response) => {
																const index = this.globals.projeler.findIndex(elem => elem.Id === Number(this.EntryId));
																this.globals.projeler[index] = response;
																this.messageService.sendMessage('ProjeGuncellendi');
																this.showSuccess("Başvurunuz başarıyla gönderildi. “Başvurular” sayfasına yönlendiriliyorsunuz.");
																this.router.navigateByUrl('/dashboard');
															},
															error => {
																//  console.log('Error', error)
																this.showError('Bir hata oluştu, Lütfen tekrar deneyiniz');
															}
														);
													}

												}).catch();
											}

										}).catch();
									}

								}).catch();
							}

						}).catch();
					}

				}).catch();
			}

		}).catch();
		// return;
	}

	RokIzinChanged() {
		// this.PageData.rok = val;
		if(this.PageData.rok){
			console.log("sadasdasad")
		}
	}
}
