<section id="IcerikContainer">
    <div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
        <article id="content" class="dev-flex-child">
            <div class="wizard-v4-content">
                <div class="wizard-form">
                    <div class="wizard-header">
                        <h3 class="heading">Başvuru Güncelleme</h3>
                        <!-- <p>Formu doldurup bir sonraki adıma geçiniz, veya istediğiniz adıma geçebilirsiniz.</p> -->
                    </div>
                    <div class="form-register" action="#" method="post" *ngIf="Yuklendi === true && PageData.Adim11Durum === 0">
                        <div id="form-total" role="application" class="wizard clearfix">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(1)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">1</span>
                                                <span class="step-text">Kategori Seçimi</span>
                                                <span class="badge" *ngIf="PageData.Adim1Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(2)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">2</span>
                                                <span class="step-text">Künye</span>
                                                <span class="badge" *ngIf="PageData.Adim2Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="done">
                                        <a (click)="AdimDegistir(3)">
                                            <div class="title">
                                                <span class="step-cizgi-left renkli"></span>
                                                <span class="step-cizgi-right renkli"></span>
                                                <span class="step-icon">3</span>
                                                <span class="step-text">Özet</span>
                                                <span class="badge" *ngIf="PageData.Adim3Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>

                                    <ng-container *ngIf="Adim === 4">
                                        <li role="tab" class="done">
                                            <a>
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">4</span>
                                                    <span class="step-text">Hedef</span>
                                                    <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(5)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">5</span>
                                                    <span class="step-text">Fikir</span>
                                                    <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(6)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">6</span>
                                                    <span class="step-text">Uygulama</span>
                                                    <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(7)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">7</span>
                                                    <span class="step-text">Sonuç</span>
                                                    <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>

                                    <ng-container *ngIf="Adim === 5">
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(4)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">4</span>
                                                    <span class="step-text">Hedef</span>
                                                    <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a>
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">5</span>
                                                    <span class="step-text">Fikir</span>
                                                    <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(6)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">6</span>
                                                    <span class="step-text">Uygulama</span>
                                                    <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(7)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">7</span>
                                                    <span class="step-text">Sonuç</span>
                                                    <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>

                                    <ng-container *ngIf="Adim === 6">
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(4)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">4</span>
                                                    <span class="step-text">Hedef</span>
                                                    <span class="badge" *ngIf="PageData.Adim4Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(5)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">5</span>
                                                    <span class="step-text">Fikir</span>
                                                    <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a>
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">6</span>
                                                    <span class="step-text">Uygulama</span>
                                                    <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="">
                                            <a (click)="AdimDegistir(7)">
                                                <div class="title">
                                                    <span class="step-cizgi-left"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">7</span>
                                                    <span class="step-text">Sonuç</span>
                                                    <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>

                                    <ng-container *ngIf="Adim === 7">
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(4)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">4</span>
                                                    <span class="step-text">Hedef</span>
                                                    <span class="badge" *ngIf="PageData.Adim4Durum == 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(5)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">5</span>
                                                    <span class="step-text">Fikir</span>
                                                    <span class="badge" *ngIf="PageData.Adim5Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a (click)="AdimDegistir(6)">
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right renkli"></span>
                                                    <span class="step-icon">6</span>
                                                    <span class="step-text">Uygulama</span>
                                                    <span class="badge" *ngIf="PageData.Adim6Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li role="tab" class="done">
                                            <a>
                                                <div class="title">
                                                    <span class="step-cizgi-left renkli"></span>
                                                    <span class="step-cizgi-right"></span>
                                                    <span class="step-icon">7</span>
                                                    <span class="step-text">Sonuç</span>
                                                    <span class="badge" *ngIf="PageData.Adim7Durum === 0">!</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ng-container>

                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(8)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">8</span>
                                                <span class="step-text">Dosya Yükleme</span>
                                                <span class="badge" *ngIf="PageData.Adim8Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(9)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">9</span>
                                                <span class="step-text">Sayfa Sayısı Doğrulama</span>
                                                <span class="badge" *ngIf="PageData.Adim9Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(10)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">10</span>
                                                <span class="step-text">İzinler Belgesi</span>
                                                <span class="badge" *ngIf="PageData.Adim10Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li role="tab" class="">
                                        <a (click)="AdimDegistir(11)">
                                            <div class="title">
                                                <span class="step-cizgi-left"></span>
                                                <span class="step-cizgi-right"></span>
                                                <span class="step-icon">11</span>
                                                <span class="step-text">Başvur</span>
                                                <span class="badge" *ngIf="PageData.Adim11Durum === 0">!</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="content clearfix">
                                <form class="form form-horizontal AdimliForm" novalidate="">

                                    <ng-container *ngFor="let item of Sorular; let i = index;">
                                        <!-- {{item | json}} -->
                                        <!-- {{item.Adim}}// {{item.Id}} // {{item.Soru.Type}}<br> -->
                                        <ng-container *ngIf="item.Soru.Type == 'Metin'">
                                            <p style="max-width: none; width: 100%;" [innerHTML]="item.Soru.Text"></p>
                                        </ng-container>

                                        <ng-container *ngIf="item.Soru.Type == 'SoruText'">
                                            <div class="form-body">
                                                <div class="form-group row NoMarginLeftRight" *ngIf="PageData.SoruCevap[i].Cevap.IlgiliSoru.Id > 0">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{GetIlgiliSoru(PageData.SoruCevap[i].Cevap.IlgiliSoru.Id)}}</label>
                                                </div>

                                                <div class="form-group" style="background-color: lightgray;" *ngIf="PageData.SoruCevap[i].Cevap.IlgiliSoru.Id > 0">
                                                    <!-- <label class="col-md-12 label-control" style="padding: 10px; background-color: lightgray;">{{GetIlgiliSoru(PageData.SoruCevap[i].Cevap.IlgiliSoru.Id)}}</label> -->
                                                    <quill-editor [readOnly]="true" [styles]="{height: '250px', backgroundColor : 'lightgray'}" [modules]="{toolbar: false }" name="disabled{{i}}" [ngModel]="GetIlgiliCevap(PageData.SoruCevap[i].Cevap.IlgiliSoru.Id)"></quill-editor>
                                                </div>
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{item.Soru.Soru}}</label>
                                                </div>
                                                <!--  -->
                                                <div class="form-group" style="background-color: white;">
                                                    <p *ngIf="item.Soru.Dipnot.length" style="max-width: none; width: 100%; margin-bottom: 0px; padding: 4px;" [innerHTML]="item.Soru.Dipnot"></p>
                                                    <quill-editor [styles]="{height: '250px'}" [modules]="{
                                                            toolbar: [
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                
                                                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                                [{ 'script': 'sub'}, { 'script': 'super' }],
                                                                [{ 'align': [] }],
                                                                ['link', 'image']
                                                            ]
                                                            }" placeholder="Açıklama giriniz..." (onEditorCreated)="EditorCreated($event, i)" name="{{i}}" [(ngModel)]="PageData.SoruCevap[i].Cevap.Cevap"></quill-editor>
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>

                                        <ng-container *ngIf="item.Soru.Type == 'Radio'">
                                            <div class="form-body" style="border-bottom: 1px solid rgb(177, 152, 92);">
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{item.Soru.Soru}}</label>
                                                </div>
                                                <div class="form-group" style="background-color: white; display: table;width:100%;">
                                                    <p *ngIf="item.Soru.Dipnot.length" style="max-width: none; width: 100%; margin-bottom: 0px; padding: 4px;" [innerHTML]="item.Soru.Dipnot"></p>
                                                    <div class="col-4" style="background-color: white; float: left;" *ngFor="let RadioItem of item.Soru.Radios; let RadioIndex = index;">

                                                        <div class="form-check" (click)="RadioChanged(i, RadioIndex)">
                                                            <i class="far fa-check-circle" style="margin-right: 10px; font-size: 14px; color: green;" *ngIf="PageData.SoruCevap[i].Cevap.Radios[RadioIndex].Value === true"></i>
                                                            <i class="far fa-circle" style="margin-right: 10px; font-size: 14px;" *ngIf="PageData.SoruCevap[i].Cevap.Radios[RadioIndex].Value === false"></i>
                                                            <label class="form-check-label CheckboxTitle">
                                                                {{RadioItem.Name}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>

                                        <ng-container *ngIf="item.Soru.Type == 'CheckBox'">
                                            <div class="form-body" style="border-bottom: 1px solid rgb(177, 152, 92);">
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{item.Soru.Soru}}</label>
                                                </div>
                                                <div class="form-group" style="background-color: white; display: table;width:100%;">
                                                    <p *ngIf="item.Soru.Dipnot.length" style="max-width: none; width: 100%; margin-bottom: 0px; padding: 4px;" [innerHTML]="item.Soru.Dipnot"></p>
                                                    <div class="col-4 NoPadding" style="background-color: white; float: left; height: 38px; line-height: 38px;" *ngFor="let CheckboxItem of item.Soru.CheckBoxes; let CheckBoxIndex = index;">


                                                        <div class="form-check" (click)="CheckBoxChanged(i, CheckBoxIndex)" *ngIf="CheckboxItem.Type == 'CheckBox'">
                                                            <i class="far fa-check-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; color: green;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Value === true"></i>
                                                            <i class="far fa-square" style="margin-right: 10px; font-size: 14px; line-height: 38px;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Value === false"></i>
                                                            <label class="form-check-label CheckboxTitle">
                                                                {{CheckboxItem.Name}}
                                                            </label>
                                                        </div>
                                                        <div class="form-check" (click)="CheckBoxChanged(i, CheckBoxIndex)" *ngIf="CheckboxItem.Type == 'CheckBoxText'">
                                                            <i class="far fa-check-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; color: green; float: left;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Value === true" (click)="DigerCheckboxClick(false, i, CheckBoxIndex)"></i>
                                                            <i class="far fa-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; float: left;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Value === false" (click)="DigerCheckboxClick(true, i, CheckBoxIndex)"></i>
                                                            <input class="form-control" type="text" [disabled]="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Value === false" style="width: calc(100% - 30px); float: left;" name="CheckBox{{CheckBoxIndex}}" placeholder="{{CheckboxItem.Name}}" maxlength="250"
                                                                [(ngModel)]="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxIndex].Cevap">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>

                                        <ng-container *ngIf="item.Soru.Type == 'MultiCheckBox'">
                                            <div class="form-body" style="border-bottom: 1px solid rgb(177, 152, 92);">
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{item.Soru.Soru}}</label>
                                                </div>
                                                <div class="form-group" style="background-color: white; display: table;width:100%;">
                                                    <p *ngIf="item.Soru.Dipnot.length" style="max-width: none; width: 100%; margin-bottom: 0px; padding: 4px;" [innerHTML]="item.Soru.Dipnot"></p>

                                                    <ng-container *ngFor="let CheckboxGrupItem of item.Soru.CheckBoxes; let CheckBoxGrupIndex = index;">
                                                        <div class="col-12" style="display: inline-block;">
                                                            <p style="max-width: none; width: 100%; font-weight: 600; margin-top: 5px; margin-bottom: 5px; padding: 4px; color:white; background: rgb(177, 152, 92); border-top: 1px solid rgb(177, 152, 92); border-bottom: 1px solid rgb(177, 152, 92);" [innerHTML]="CheckboxGrupItem.Grup"></p>
                                                            <div class="col-4 NoPadding" style="background-color: white; float: left; height: 38px; line-height: 38px;" *ngFor="let CheckboxItem of CheckboxGrupItem.SubCheckBoxes; let CheckBoxIndex = index;">
                                                                <div class="form-check" (click)="CheckBoxGrupChanged(i, CheckBoxGrupIndex, CheckBoxIndex)" *ngIf="CheckboxItem.Type == 'CheckBox'">
                                                                    <i class="far fa-check-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; color: green;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value === true"></i>
                                                                    <i class="far fa-square" style="margin-right: 10px; font-size: 14px; line-height: 38px;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value === false"></i>
                                                                    <label class="form-check-label CheckboxTitle" style="font-weight: 400;">
                                                                        {{CheckboxItem.Name}}
                                                                    </label>
                                                                </div>
                                                                <div class="form-check" *ngIf="CheckboxItem.Type == 'CheckBoxText'">
                                                                    <!-- {{PageData.SoruCevap[i].Cevap | json}} -->
                                                                    <i class="far fa-check-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; color: green; float: left;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value === true" (click)="DigerCheckboxGrupClick(false, i, CheckBoxGrupIndex, CheckBoxIndex)"></i>
                                                                    <i class="far fa-square" style="margin-right: 10px; font-size: 14px; line-height: 38px; float: left;" *ngIf="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value === false" (click)="DigerCheckboxGrupClick(true, i, CheckBoxGrupIndex, CheckBoxIndex)"></i>
                                                                    <input class="form-control" type="text" [disabled]="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Value === false" style="width: calc(100% - 30px); float: left;" name="MultiCheckBox{{i}}-{{CheckBoxIndex}}-{{CheckBoxGrupIndex}}-{{CheckBoxIndex}}"
                                                                        placeholder="{{CheckboxItem.Name}}" maxlength="250" [(ngModel)]="PageData.SoruCevap[i].Cevap.CheckBoxes[CheckBoxGrupIndex].SubCheckBoxes[CheckBoxIndex].Cevap">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>

                                        <ng-container *ngIf="item.Soru.Type == 'Kaynak'">
                                            <div class="form-body">
                                                <div class="form-group row NoMarginLeftRight">
                                                    <label class="col-md-12 label-control" style="padding: 10px; color: white;">{{item.Soru.Soru}}</label>
                                                </div>
                                                <div class="form-group" style="background-color: white;">
                                                    <p *ngIf="item.Soru.Dipnot.length" style="max-width: none; width: 100%; margin-bottom: 0px; padding: 4px;" [innerHTML]="item.Soru.Dipnot"></p>
                                                    <quill-editor [styles]="{height: '250px'}" [modules]="{
                                                            toolbar: [
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                
                                                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                                [{ 'script': 'sub'}, { 'script': 'super' }],
                                                                [{ 'align': [] }],
                                                                ['link', 'image']
                                                            ]
                                                            }" placeholder="Açıklama giriniz..." (onEditorCreated)="EditorCreated($event, i)" name="{{i}}" [(ngModel)]="PageData.SoruCevap[i].Cevap"></quill-editor>
                                                </div>
                                            </div>
                                            <br>
                                        </ng-container>
                                        <!-- 
                                        
                                        
                                        

                                         -->
                                    </ng-container>
                                </form>
                            </div>
                            <div class="actions clearfix">
                                <ul role="menu" class="Pagination">
                                    <li (click)="AdimDegistir(-1)"><a role="menuitem">Bir Önceki Adım</a></li>
                                    <li (click)="AdimDegistir(0)"><a role="menuitem">Bir Sonraki Adım</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>