import { Component, OnInit, NgZone } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { MessageServiceService } from '../services/message-service.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
	selector: 'app-ucretlendirme',
	templateUrl: './ucretlendirme.component.html',
	styleUrls: ['./ucretlendirme.component.css']
})
export class UcretlendirmeComponent implements OnInit {
	Icerik = ""
	subscription: Subscription;
	constructor(
		private globals: Globals,
		private messageService: MessageServiceService,
		private zone: NgZone,
	) {
		this.Icerik = this.globals.Yonetim.Ucretlendirme
	}

	ngOnInit() {
		this.subscription = this.messageService.getMessage().subscribe(message => {
			if (message.text === 'Yonetim') {
				this.zone.run(() => {
					this.Icerik = this.globals.Yonetim.Ucretlendirme
				});
				
			}
		});
	}

	ngOnDestroy() {
		
		
		this.subscription.unsubscribe();
	}
}
