<section id="IcerikContainer">
	<div class="dev-wrapper dev-entrant-home dev-wrapper-entry-home dev-flex-container IcerikSubContainer">
		<article id="content" class="dev-flex-child" *ngIf="Yuklendi === true">
			<header class="dev-entrant-layout">
				<h1>Hesap Bilgileri</h1>
				<div style="margin-top: 10px;">

					<div class="dev-dataBlock dev-mandatory" style="margin-bottom: 5px;">
						<label style="width: 100%;">
							<span>E-Posta</span>
						</label>
						<input disabled type="email" name="EPosta" [(ngModel)]="PageData.Email" required="required"
							class="dev-long" autocapitalize="off" autocomplete="off">
					</div>
					<div class="dev-dataBlock dev-mandatory" style="margin-bottom: 5px;">
						<label style="width: 100%;">
							<span>Adı, Soyadı</span>
						</label>
						<input type="text" name="AdSoyad" [(ngModel)]="PageData.AdSoyad" required="required" class="dev-long"
							[ngClass]="AdSoyadClass" autocapitalize="off" autocomplete="off">
						<div *ngIf="AdSoyadClass === 'invalid'" class="invalid-feedback">Ad Soyadınız en az 5
							karakterden oluşmalıdır.
						</div>
					</div>
					<div class="dev-dataBlock dev-mandatory" style="margin-bottom: 5px;">
						<label style="width: 100%;">
							<span>Firma Adı</span>
						</label>
						<input type="text" name="FirmaAdi" [(ngModel)]="PageData.FirmaAdi" required="required" class="dev-long"
							[ngClass]="FirmaAdiClass" autocapitalize="off" autocomplete="off">
						<div *ngIf="FirmaAdiClass === 'invalid'" class="invalid-feedback">Firma Adınız en az 5
							karakterden oluşmalıdır.
						</div>
					</div>
					<div class="dev-dataBlock dev-mandatory" style="margin-bottom: 5px;">
						<label style="width: 100%;">
							<span>Görevi</span>
						</label>
						<input type="text" name="Gorevi" [(ngModel)]="PageData.Gorev" required="required" class="dev-long"
							[ngClass]="GoreviClass" autocapitalize="off" autocomplete="off">
						<div *ngIf="GoreviClass === 'invalid'" class="invalid-feedback">Goreviniz en az 5 karakterden
							oluşmalıdır.
						</div>
					</div>
					<div class="dev-dataBlock dev-mandatory" style="margin-bottom: 5px;">
						<label style="width: 100%;">
							<span>Telefon</span>
						</label>
						<input type="text" name="Telefon" [(ngModel)]="PageData.Telefon" required="required" class="dev-long"
							[ngClass]="TelefonClass" autocapitalize="off" autocomplete="off" myNumberOnly>
						<div *ngIf="TelefonClass === 'invalid'" class="invalid-feedback">Telefon numaranız en az 5
							karakterden oluşmalıdır.
						</div>
					</div>
					<div class="dev-toolBlock">
						<button [ladda]="isLoading" class="dev-button dev-hot nav-submit" (click)="Kaydet()">KAYDET</button>
					</div>

				</div>
			</header>

		</article>
	</div>
</section>
